<template>
  <div class="columns is-vcentered">
    <div class="column">
      <div>
        <a href="/">
          <figure class="image is-flex is-inline-block is-96x96">
            <img v-if="bgLight" src="../../assets/logo-metas-dark.png" />
            <img v-else src="../../assets/logo-metas-light.png" />
          </figure>
        </a>
      </div>
    </div>
    <div class="column has-text-right">
      <div>
        <a href="/intranet" class="button" :class="{'button-dark': bgLight}">INTRANET</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
  props: ["bg-light"]
};
</script>

<style scoped>
.button {
  width: 160px;
  height: 52px !important;
  background-color: rgba(217, 217, 217, 0.75) !important;
  border-color: transparent !important;
  border-radius: 5px !important;
  color: white !important;
  font-family: "Inter";
  font-size: 14px !important;
  letter-spacing: 0.105em;
}

.button-dark {
    background-color: rgba(92, 92, 92, 0.75) !important;
}
</style>