import { createApp } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import Intranet from './components/intranet/Intranet';

const PageNotFound = { template: '<h1>Not found</h1>' }

const routes = [
    { path: '/', name: 'home', component: App },
    { path: '/nosotros', name: 'nosotros', component: App },
    { path: '/servicios', name: 'servicios', component: App },
    { path: '/proyectos', name: 'proyectos', component: App },
    { path: '/contacto', name: 'contacto', component: App },
    { path: '/clientes', name: 'clientes', component: App },
    { path: '/intranet', name: 'intranet', component: Intranet },
    { path: "/:pathMatch(.*)*", component: PageNotFound }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes: routes // short for `routes: routes`
})

const app = createApp({})
app.use(router)
app.mount('#app')

require('@/assets/main.scss');
