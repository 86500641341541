<script>
import eventBus from "../../../eventBus";


export const ServiciosMobile = {
  render() {
    return (
      <div class="columns is-mobile is-centered has-text-centered is-vcentered">
        <div class="column">
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 1)}}>
            <p class="box-text">Área eléctrica</p>
          </div>
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 2)}}>
            <p class="box-text">Instalaciones Eléctricas Internas</p>
          </div>
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 3)}}>
            <p class="box-text">Subestaciones de Media y Baja Tensión</p>
          </div>
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 4)}}>
            <p class="box-text">Sistema de puesta en tierra</p>
          </div>
        </div>
        <div class="column">
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 2)}}>
            <p class="box-text">Subestaciones Media Tensión</p>
          </div>
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 3)}}>
            <p class="box-text">Diseño</p>
          </div>
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 4)}}>
            <p class="box-text">Red de media y baja tensión</p>
          </div>
          <div class="box is-clickable" onClick={() => {eventBus.$emit('servicios-navigate', 5)}}>
            <p class="box-text">Mantenimientos preventivos y correctivos</p>
          </div>
        </div>
      </div>
    );
  },
};

export const ServiciosMobileAreaElectrica = {
  render() {
    return (
      <div class="mt-5">
        <h1 class="nosotros-title-mobile mb-5 has-text-centered margin-servicios-mobile-1">
          Área Eléctrica
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Sistemas de puesta a tierra</li>
            <li>Instalaciones eléctricas en media y baja tensión</li>
            <li>Sistemas internos y externos de iluminación</li>
            <li>Acometidas eléctricas, redes eléctricas internas y externas</li>
            <li>Colocación de biométricos</li>
            <li>Canalizaciones vacías para voz-datos</li>
            <li>Colocación de placas en apagadores</li>
            <li>Conexión de mamparas</li>
            <li>Levantamientos y mantenciones eléctricas</li>
            <li>Colocación de transformadores y tableros TGN</li>
            <li>
              Diseño ejecutivo para las instalaciones eléctricas de alumbrado
            </li>
            <li>
              Arreglo de equipos y canalizaciones vacías paralas instalaciones
              especiales
            </li>
            <li>Elaboración de soporte para instalaciones</li>
            <li>
              Suministro, colocación, mano de obra, movimientos dentro de la
              obra
            </li>
            <li>
              Elaboración de soporte para instalación de canalizaciones en losa.
            </li>
            <li>
              Parametrización de ccm’s y calibración de interruptores
              electromagneticos
            </li>
          </ul>
        </div>
      </div>
    );
  },
};

export const ServiciosMobileServicios1 = {
  render() {
    return (
      <div class="margin-servicios-mobile-1">
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Subestaciones Media Tensión
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Sistemas de puesta a tierra</li>
            <li>Instalaciones eléctricas en media y baja tensión</li>
            <li>Sistemas internos y externos de iluminación</li>
            <li>Acometidas eléctricas, redes eléctricas internas y externas</li>
            <li>Colocación de biométricos</li>
          </ul>
        </div>
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Instalaciones Eléctricas Internas
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Canalizaciones vacías para voz-datos</li>
            <li>Colocación de placas en apagadores</li>
            <li>Conexión de mamparas</li>
            <li>Levantamientos y mantenciones eléctricas</li>
            <li>Colocación de transformadores y tableros TGN</li>
            <li>
              Diseño ejecutivo para las instalaciones eléctricas de alumbrado
            </li>
          </ul>
        </div>
      </div>
    );
  },
};

export const ServiciosMobileServicios2 = {
  render() {
    return (
      <div class="margin-servicios-mobile-1">
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Diseño
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>
              Arreglo de equipos y canalizaciones vacías para las instalaciones
              especiales
            </li>
            <li>Elaboración de soporte para instalaciones</li>
            <li>
              Suministro, colocación, mano de obra, movimientos dentro de la
              obra
            </li>
            <li>
              Elaboración de soporte para instalación de canalizaciones en losa.
            </li>
            <li>Colocación de transformadores y tableros TGN</li>
            <li>
              Parametrización de ccm’s y calibración de interruptores
              electromagneticos
            </li>
          </ul>
        </div>
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Subestaciones de Media y Baja Tensión
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Suministro e instalación de transformador</li>
            <li>Suministro e instalación de elementos de protección</li>
            <li>Suministro e instalación de equipos de medida</li>
            <li>Suministro e instalación de celdas</li>
          </ul>
        </div>
      </div>
    );
  },
};

export const ServiciosMobileServicios3 = {
  render() {
    return (
      <div class="margin-servicios-mobile-1">
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Red de media y baja tensión
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Suministro e instalación de elementos que componen la red</li>
            <li>Instalación de alumbrado público</li>
            <li>Medición termografía</li>
          </ul>
        </div>
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Sistema de puesta en tierra
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Medición de resistividad de terreno</li>
            <li>Medición de resistividad de la malla de puesta a tierra</li>
            <li>Diseño de la malla de puesta a tierra</li>
            <li>
              Suministro e Instalación de los diferentes elementos que componen
              el Sistema de Puesta a Tierra
            </li>
          </ul>
        </div>
      </div>
    );
  },
};

export const ServiciosMobileServicios4 = {
  render() {
    return (
      <div class="margin-servicios-mobile-1">
        <h1 class="nosotros-title-mobile mb-5 mt-5 has-text-centered">
          Mantenimientos preventivos y correctivos
        </h1>
        <div class="content has-text-left">
          <ul class="servicios-list mt-4">
            <li>Termografía</li>
            <li>
              Sistema de puesta a tierra: verificación de conexiones y medida de
              resistividad de tierra
            </li>
            <li>Revisión y mantenimiento de subestaciones</li>
            <li>Mantenimiento de luminarias</li>
            <li>Mantenimiento de tableros</li>
            <li>Marcación de gabinetes de voz y datos</li>
            <li>Propuestas de ahorro de energía</li>
          </ul>
        </div>
      </div>
    );
  },
};

export default ServiciosMobile;
</script>

<style>
.box {
  background-color: rgba(255, 255, 255, 0.5) !important;
  height: 100px;
  position: relative;
}

.box-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.margin-servicios-mobile-1 {
  margin-top: 20px;
}
</style>