<script>
export const FormMobile = {
  render() {
    return (
      <div class="margin-top-mobile-contacto has-text-centered">
        <h1 class="contacto-title-mobile">Contáctanos hoy</h1>
        <p class="form-text-mobile mt-5">Comunícate con nosotros.</p>
        <p class="form-text-mobile">
          Nuestra atención telefónica está disponible para responder a tus
          preguntas
        </p>
        <div class="has-text-left mt-5">
          <input
            class="input contacto-input"
            type="text"
            placeholder="Nombre"
          />
          <input
            class="input contacto-input mt-3"
            type="text"
            placeholder="E-mail"
          />
          <input
            class="input contacto-input mt-3"
            type="text"
            placeholder="Asunto"
          />
          <textarea
            class="textarea contacto-input mt-3"
            placeholder="Mensaje"
          ></textarea>
          <button class="button button-contacto mt-3">Enviar</button>
        </div>
      </div>
    );
  },
};

export const ContactoMobile = {
  render() {
    return (
      <div class="margin-top-mobile-contacto has-text-left">
        <p class="contacto-text-mobile has-text-justified">
          Ya sea una pregunta sobre soluciones de diseño, una nueva consulta
          comercial o simplemente una solicitud de chat informal, siempre
          encontrará a Grupo Albarrán disponible a través de este formulario de
          contacto.
        </p>
        <h2 class="contacto-subtitle-mobile margin-top-mobile-contacto-big">DIRECCIÓN</h2>
        <p class="contacto-text-mobile mt-1">Antara Polanco</p>
        <p class="contacto-text-mobile">Av Ejército Nacional 843-B</p>
        <p class="contacto-text-mobile">Granada, 11520</p>
        <p class="contacto-text-mobile">Ciudad de México</p>
        <h2 class="contacto-subtitle-mobile mt-3">CONTACTO</h2>
        <p class="contacto-text-mobile mt-1">hola@albarran.com</p>
        <p class="contacto-text-mobile">+52 (55) 59 85 07 00</p>
        <p class="contacto-text-mobile mt-3">
          Acepto la{" "}
          <span class="has-text-weight-bold">Politica de privacidad</span>
        </p>
      </div>
    );
  },
};

export default ContactoMobile;
</script>

<style>
h1.contacto-title-mobile {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

h2.contacto-subtitle-mobile {
  font-size: 12px;
  font-weight: 600;
}

p.form-text-mobile {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

p.contacto-text-mobile {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.margin-top-mobile-contacto {
  margin-top: 50px;
}

.margin-top-mobile-contacto-big {
  margin-top: 130px;
}
</style>