<template>
  <div class="box login-box center">
    <div class="has-text-centered">
      <figure class="image is-inline-block is-128x128">
        <img src="../../assets/logo-albarran-black.png" />
      </figure>
      <h1 class="has-intranet-login-text">INTRANET</h1>
      <div class="mt-6">
        <input
          class="input login-input"
          type="text"
          placeholder="Usuario"
          v-model="name"
        />
        <input
          class="input login-input mt-3"
          type="password"
          placeholder="Contraseña"
          v-model="password"
        />
        <button class="button login-button mt-6" @click="login()">
          Entrar
        </button>
        <div v-if="error" class="mt-3">
          <p class="error">Usuario y contraseña incorrectos</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      password: "",
      error: false,
    };
  },
  methods: {
    login() {
      this.error = false;
      console.log(this.name);
      console.log(this.password);
      if (this.name === "admin" && this.password === "grupoalbarran136") {
        this.$emit("log-in");
        return;
      }
      this.error = true;
      return;
    },
  },
};
</script>

<style>
.has-intranet-login-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4em;
  color: #838181;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 20px !important;
  width: 318px;
  height: 469px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-input {
  color: #000 !important;
  height: 37px !important;
  border-style: none !important;
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
  font-size: 12px !important;
  padding-left: 20px !important;
}

.login-button {
  border-style: none !important;
  background-color: #d9d9d9 !important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 15px !important;
  width: 100px;
  height: 37px !important;
}

.error {
  font-size: 12px;
}
</style>