<template>
    <div v-if="showButtons" id="navigation-buttons">
        <div v-if="isMobile" class="mobile-buttons">
            <div v-if="showUp" class="button-left">
                <button class="button" @click="clickButton('up')">
                    <span class="icon">
                    <i class="fa fa-chevron-left"></i>
                    </span>
                </button>
            </div>
            <div v-if="showDown" class="button-right">
                <button class="button" @click="clickButton('down')">
                    <span class="icon">
                    <i class="fa fa-chevron-right"></i>
                    </span>
                </button>
            </div>
        </div>
        <div v-else>
            <div v-if="showUp" class="button-up">
                <button class="button" @click="clickButton('up')">
                    <span class="icon">
                    <i class="fa fa-chevron-up"></i>
                    </span>
                </button>
            </div>
            <div v-if="showDown" class="button-down">
                <button class="button" @click="clickButton('down')">
                    <span class="icon">
                    <i class="fa fa-chevron-down"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavigationButton",
    data() {
        return {
            showButtons: true,
            timeout: null
        }
    },
    props: ["show-up", "show-down", "is-mobile"],
    mounted() {
        this.timeout = setTimeout(() => {
            this.showButtons = false
        }, "3000")
        addEventListener("mousemove", () => {
            clearTimeout(this.timeout)
            if (!this.showButtons) {
                this.showButtons = true
            }
            this.timeout = setTimeout(() => {
                this.showButtons = false
            }, "3000")
        });
    },
    methods: {
        clickButton(direction) {
            clearTimeout(this.timeout)
            this.$emit('navigate', direction)
            this.timeout = setTimeout(() => {
                this.showButtons = false
            }, "3000")
        }
    }
}
</script>

<style>
.button-up {
    width: 100%;
    justify-content: center;
    position: fixed !important;
    top: 10px;
    display: flex;
}

.button-down {
    width: 100%;
    justify-content: center;
    position: fixed !important;
    bottom: 10px;
    display: flex;
}

.mobile-buttons {
    position: fixed !important;
    flex-direction: row;
    display: flex;
    width: 100%;
    bottom: 50%;
}

.button-left {
    width: 100%;
    display: flex;
    justify-content: left;
}

.button-right {
    width: 100%;
    display: flex;
    justify-content: right;
}

#navigation-buttons .button {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-style: none;
    border-width: 0;
    color: white;
}

</style>