<script>
export const Proyectos = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <h1 class="proyectos-title">PROYECTOS</h1>
          <div class="space-2"></div>
          <div class="columns is-vcentered proyectos-margin">
            <div class="column">
                <h1 class="proyectos-subtitle">Universidad Iberoamericana</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Jumex = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Museo Jumex</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Banamex = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">City Banamex</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Reforma = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Punta Reforma</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Gobierno = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Museo Palacio de Gobierno</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Ibero = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Prepa Ibero</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Tamayo = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Museo Rufino Tamayo</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Express = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Hotel City Express</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Koi = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Torre Koi</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Aeropuerto = {
  render() {
    return (
      <div class="column">
        <div>
          <div class="space-4"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Aeropuerto Internacional de México</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export const Queretaro = {
  render() {
    return (
      <div class="column">
        <div class="mt-5">
          <div class="space-1"></div>
          <div class="columns is-vcentered">
            <div class="column">
                <h1 class="proyectos-subtitle">Centro Congreso Querétaro</h1>
            </div>
            <div class="column">
                <div class="div-line-proyectos"></div>
            </div>
            <div class="column">
                <h2 class="proyectos-subtitle-small">Instalación</h2>
                <p class="nosotros-text mt-2">
                    Descripción del proyecto, descripción del proyecto, descripción del proyecto, 
                    descripción del proyecto, descripción del proyecto, 
                </p>
                <div class="has-text-right mt-2">
                <a class="proyectos-link">
                    Ficha técnica
                </a>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
};

export default Proyectos;
</script>

<style>
h1.proyectos-title {
  position: relative;
  top: -200px;
  color: white;
  font-size: 60px;
  font-weight: 600;
  line-height: 72.61px;
  width: 75%;
}

@media only screen and (max-height: 650px) {
  .proyectos-margin {
    margin-top: -100px !important;
  }
}

h1.proyectos-subtitle {
  color: white !important;
  font-size: 40px;
  font-weight: 600;
}

h2.proyectos-subtitle-small {
  color: white !important;
  font-size: 24px;
  font-weight: 500;
}

p.nosotros-text {
  color: white;
  font-size: 13px;
  line-height: 16px;
}

.proyectos-link {
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-bottom: 50px;
  text-decoration: underline;
}

.div-line-proyectos {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: rgba(217, 217, 217, 0.25);
}

@media only screen and (min-height: 650px) {
  .space-4 {
      height: 100px
  }
}
</style>