<template>
  <div class="margin-top-clientes has-text-centered">
    <figure class="image is-flex is-inline-block mt-5">
      <img src="../../../assets/clientes-logo-1.png" />
    </figure>
    <figure class="image is-flex is-inline-block mt-5">
      <img src="../../../assets/clientes-logo-2.png" />
    </figure>
    <figure class="image is-flex is-inline-block mt-5">
      <img src="../../../assets/clientes-logo-3.png" />
    </figure>
    <figure class="image is-flex is-inline-block mt-5">
      <img src="../../../assets/clientes-logo-4.png" />
    </figure>
    <figure class="image is-flex is-inline-block mt-5">
      <img src="../../../assets/clientes-logo-5.png" />
    </figure>
  </div>
</template>

<script>
export default {
  name: "Clientes"
};
</script>

<style>
.margin-top-clientes {
  margin-top: 50px;
}
</style>
