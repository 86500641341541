<template>
    <div>
        <div class="columns is-vcentered">
            <div class="column has-text-centered is-one-fifth">
                <figure class="image is-inline-block is-128x128">
                    <img src="../../assets/logo-metas-home.png" />
                </figure>
            </div>
            <div class="column is-half">
                <h2 class="client-name">Jorge Alcedo Rodriguez</h2>
                <p class="placeholder-text">Cliente</p>
            </div>
            <div class="column is-flex proyecto-column">
                <p class="placeholder-text">Proyecto Actual: </p>
                <p class="ml-2 proyecto-name">Museo Jumex</p>
                <span class="icon ml-5">
                    <i class="fa-solid fa-bars fa-2xl"></i>
                </span>
            </div>
        </div>
        <div class="box dashboard-box center">
            <div class="columns">
                <div class="column is-one-fifth has-text-centered">
                    <div class="dashboard-container">
                       <figure class="image is-inline-block is-96x96 mt-3">
                            <img src="../../assets/dashboard-user-icon.png" />
                        </figure>
                        <p class="placeholder-text-box mt-6">Cliente:</p>
                        <p class="dashboard-text-box mt-1">Jorge Alcedo Rodriguez</p>
                        <p class="placeholder-text-box mt-2">Telefono:</p>
                        <p class="dashboard-text-box mt-1">5612088707</p>
                        <p class="placeholder-text-box mt-2">RFC:</p>
                        <p class="dashboard-text-box mt-1">JOAR670522S32</p>
                        <p class="placeholder-text-box mt-2">ID de Cliente:</p>
                        <p class="dashboard-text-box-id mt-1">309020</p>
                    </div>
                </div>
                <div class="column dashboard-docs">
                    <div class="columns">
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                        <div class="column is-one-fifth">
                            <div class="dashboard-container-small">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="has-text-right">
                <button class="button dashboard-button is-inline-block">Subir Archivos</button>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.dashboard-box {
  background-color: #FDFDFD !important;
  border-radius: 0px !important;
  width: 100%;
  height: 510px;
}

.client-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.placeholder-text {
  font-weight: 400;
  font-size: 14px;
}

.proyecto-name {
    font-weight: 700;
    font-size: 20px;
}

.proyecto-column {
    line-height: 25px;
}

.dashboard-container {
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 10px;
    height: 395px;
}

.dashboard-container-small {
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 10px;
    height: 118px;
}

.dashboard-docs {
    border-left-width: 2px;
    border-left-color: rgba(217, 217, 217, 0.5);
    border-left-style: solid;
}

.dashboard-button {
    border-radius: 4px !important;
    background-color: #838181 !important;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 17px;
    color: #FFFFFF !important;
}

.placeholder-text-box {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #838181;
}

.dashboard-text-box {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #838181;
}

.dashboard-text-box-id {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #838181;
}
</style>