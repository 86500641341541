<template>
  <div>
    <video v-if="controllerPage.name==='home' || controllerPage.name==='home_mobile'" autoplay loop muted playsinline id="myVideo">
      <source :src="videoLink" type="video/mp4">
    </video>
    <div v-if="isMobile">
      <div id="top" class="top" :class="{'top-mobile': isMobile, 'video': controllerPage.name==='home_mobile' }" :style="style">
        <section class="section">
          <div v-if="$route.name!=='home'" class="has-text-centered mobile-route-title" :class="{'light-route-title': controllerPage.bgLight}">
            <p>{{$route.name.toUpperCase()}}</p>
          </div>
          <Proxy :component-selector="controllerPage.name" />
        </section>
        <Menu
          :selected-page="$route.name" 
          :is-complete="controllerPage.menuComplete"
          :bg-light="controllerPage.bgLight === true"
          :is-mobile="isMobile"
        />
        <NavigationButton
          :is-mobile="isMobile"
          :show-up="showUp"
          :show-down="showDown"
          @navigate="navigate"
        />
      </div>
    </div>
    <div v-else>
      <div id="top" class="top" :style="style" :class="{ video: controllerPage.name==='home' }">
        <NavigationButton
          :show-up="showUp"
          :show-down="showDown"
          @navigate="navigate"
        />
        <section class="section">
          <Top :bg-light="controllerPage.bgLight === true" :class="{'animate__animated animate__fadeInUp': controllerPage.name==='home'}"/>
          <div class="columns" :style="controllerPage.belowMenu ? {flexDirection: 'column-reverse'}: {}">
              <Proxy :component-selector="controllerPage.name" />
              <Menu 
                :selected-page="$route.name" 
                :is-complete="controllerPage.menuComplete"
                :bg-light="controllerPage.bgLight === true"
                :class="{ 'animate__animated animate__fadeInUp animate__delay-1s': controllerPage.name==='home' }"
              />
          </div>
        </section>
        <div class="has-text-centered" v-if="$route.name === 'home'">
          <figure class="image is-flex fixed-logos animate__animated animate__fadeInUp animate__delay-2s">
            <img class="image-size" src="./assets/client-logos.png" style="" />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "./components/common/Top.vue";
import Menu from "./components/common/Menu.vue";
import NavigationButton from "./components/common/NavigationButton.vue";
import Proxy from "./components/Proxy.vue";
import eventBus from "./eventBus";


const pagesControllers = {
  home: [{ name: "home", bg: "bg-home-1.png", belowMenu: false, menuComplete: true }],
  nosotros: [
    { name: "nosotros_ga", bg: ["bg-nosotros-1.png", "dark"], belowMenu: false, menuComplete: true },
    { name: "nosotros_mision", bg: "bg-nosotros-2.png", belowMenu: false, menuComplete: true },
    { name: "nosotros_vision", bg: "bg-nosotros-3.png", belowMenu: false, menuComplete: true },
    { name: "nosotros_valores", bg: "bg-nosotros-4.png", belowMenu: false, menuComplete: true },
    { name: "nosotros_valores_detail", bg: "bg-nosotros-4.png", belowMenu: true, menuComplete: false },
    { name: "nosotros_carta", bg: "bg-light.png", belowMenu: true, menuComplete: false, bgLight: true }
  ],
  servicios: [
    { name: "servicios_servicios", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: true },
    { name: "servicios_area_electrica", bg: ["bg-servicios-2.png", "light"], belowMenu: false, menuComplete: true, bgLight: true },
    { name: "servicios_servicios1", bg: ["bg-servicios-3.png", "dark"], belowMenu: true, menuComplete: false },
    { name: "servicios_servicios2", bg: ["bg-servicios-4.png", "light"], belowMenu: true, menuComplete: false, bgLight: true },
    { name: "servicios_mantenimiento", bg: ["bg-servicios-5.png", "dark"], belowMenu: false, menuComplete: true }
  ],
  proyectos: [
    { name: "proyectos_proyectos", bg: "bg-proyectos-1.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_jumex", bg: "bg-proyectos-2.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_banamex", bg: "bg-proyectos-3.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_reforma", bg: "bg-proyectos-4.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_gobierno", bg: "bg-proyectos-5.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_ibero", bg: "bg-proyectos-6.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_tamayo", bg: "bg-proyectos-7.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_express", bg: "bg-proyectos-8.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_koi", bg: "bg-proyectos-9.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_aeropuerto", bg: "bg-proyectos-10.png", belowMenu: true, menuComplete: true },
    { name: "proyectos_queretaro", bg: "bg-proyectos-11.png", belowMenu: true, menuComplete: true }
  ],
  contacto: [
    { name: "contacto_contacto", bg: "bg-contacto-1.png", belowMenu: false, menuComplete: true },
    { name: "contacto_form", bg: "bg-light.png", belowMenu: false, menuComplete: true, bgLight: true }
  ]
};

const pageMobileControllers = {
  home: [{ name: "home_mobile", bg: "bg-home-1.png", belowMenu: false, menuComplete: true }],
  nosotros: [
    { name: "nosotros_mobile_ga", bg: ["bg-nosotros-4.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "nosotros_mobile_mision", bg: ["bg-nosotros-4.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "nosotros_mobile_valores_1", bg: ["bg-nosotros-4.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "nosotros_mobile_valores_2", bg: ["bg-nosotros-4.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "nosotros_mobile_valores_3", bg: ["bg-nosotros-4.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "nosotros_mobile_carta", bg: "bg-light.png", belowMenu: false, menuComplete: false, bgLight: true },
  ],
  servicios: [
    { name: "servicios_mobile_servicios", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "servicios_mobile_area_electrica", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "servicios_mobile_servicios_1", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "servicios_mobile_servicios_2", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "servicios_mobile_servicios_3", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: false },
    { name: "servicios_mobile_servicios_4", bg: ["bg-servicios-1.png", "dark"], belowMenu: false, menuComplete: false }
  ],
  proyectos: [
    { name: "proyectos_mobile_proyectos", bg: "bg-proyectos-1.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_jumex", bg: "bg-proyectos-2.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_banamex", bg: "bg-proyectos-3.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_reforma", bg: "bg-proyectos-4.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_gobierno", bg: "bg-proyectos-5.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_ibero", bg: "bg-proyectos-6.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_tamayo", bg: "bg-proyectos-7.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_express", bg: "bg-proyectos-8.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_koi", bg: "bg-proyectos-9.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_aeropuerto", bg: "bg-proyectos-10.png", belowMenu: false, menuComplete: false },
    { name: "proyectos_mobile_queretaro", bg: "bg-proyectos-11.png", belowMenu: false, menuComplete: false }
  ],
  contacto: [
    { name: "contacto_mobile_form", bg: "bg-proyectos-1.png", belowMenu: false, menuComplete: false },
    { name: "contacto_mobile_contacto", bg: "bg-proyectos-1.png", belowMenu: false, menuComplete: false }
  ],
  clientes: [
    { name: "clientes", bg: "bg-nosotros-3.png", belowMenu: false, menuComplete: false },
  ]
}

export default {
  name: "App",
  components: { Top: Top, Menu: Menu, Proxy: Proxy, NavigationButton: NavigationButton },
  data: () => {
    return {
      windowWidth: window.innerWidth,
      controllerPage: null,
      controllerPosition: 0,
      scrolling: false,
      lastDeltaY: 0,
      background: {
        home: "bg-home-1.png",
        nosotros: "bg-nosotros-1.png",
      },
      yDown: null
    };
  },
  methods: {
    navigate(direction) {
      if (direction === "up") {
        this.controllerPage =
          this.currentPageController[this.controllerPosition - 1];
        this.controllerPosition = this.controllerPosition - 1;
      }
      if (direction === "down") {
        this.controllerPage =
          this.currentPageController[this.controllerPosition + 1];
        this.controllerPosition = this.controllerPosition + 1;
      }
    },
    resizeLogic() {
      this.windowWidth = window.innerWidth
      if (this.isMobile) {
        this.controllerPage = pageMobileControllers[this.$route.name][this.controllerPosition]
      } else {
        this.controllerPage = pagesControllers[this.$route.name][this.controllerPosition]
      }
    },
    serviciosNavigate(page) {
      if (this.$route.name === "servicios") {
        this.controllerPage =
          this.currentPageController[page];
        this.controllerPosition = page;
      }
    }
  },
  beforeMount() {
    if (this.isMobile) {
      this.controllerPage = pageMobileControllers[this.$route.name][0];
    } else {
      this.controllerPage = pagesControllers[this.$route.name][0];
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeLogic);

    eventBus.$on('servicios-navigate', this.serviciosNavigate)
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeLogic);
  },
  computed: {
    videoLink() {
      return this.isMobile ? require('./assets/home_mobile_video.mp4') : require('./assets/home_video.mp4');
    },
    isMobile() {
      //console.log(this.windowWidth)
      return this.windowWidth <= 800
    },
    currentPageController() {
      return this.isMobile ? pageMobileControllers[this.$route.name] : pagesControllers[this.$route.name];
    },
    showUp() {
      return this.controllerPosition !== 0
    },
    showDown() {
      return this.controllerPosition !== this.currentPageController.length - 1
    },
    style() {
      if (this.controllerPage.name === "home" || this.controllerPage.name === "home_mobile") {
        return 
      }

      if (Array.isArray(this.controllerPage.bg)) {
        const gradient = this.controllerPage.bg[1] === "dark"
          ? "linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), "
          : "linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), "
        return { background: gradient + `url(${require(`./assets/${this.controllerPage.bg[0]}`)})`, backgroundSize: "cover"}
      } else {
        return {
          background: `url(${require(`./assets/${this.controllerPage.bg}`)})`, backgroundSize: "cover"
        };
      }
    },
  },
};
</script>

<style>
div#app {
  color: #ffffff;
}

.top {
  margin: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  background-position-x: center !important;
}

.top-mobile {
  height: 100vh;
}

.video {
  position: fixed;
  width: 100%;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.fixed-logos {
  justify-content: center;
  width: 100%;
  position: fixed !important;
  bottom: 0;
}

.columns {
  margin-bottom: 0 !important;
}

.mobile-route-title {
  margin-bottom: 2em;
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;
  text-decoration: underline;
}

.light-route-title {
  color: #5C5C5C !important;
}

.image-size {
  width: 90% !important;
}

@media only screen and (max-height: 650px) {
  .image-size {
    width: 80% !important;
  }
}
</style>
