<script>
export const ProyectosMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Universidad Iberoamericana</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const JumexMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Museo Jumex</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const BanamexMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">City Banamex</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const ReformaMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Punta Reforma</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const GobiernoMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Museo Palacio de Gobierno</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const IberoMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Prepa Ibero</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const TamayoMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Museo Rufino Tamayo</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const ExpressMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Hotel City Express</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const KoiMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Torre Koi</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const AeropuertoMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Aeropuerto Internacional de México</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export const QueretaroMobile = {
  render() {
    return (
      <div class="margin-top-mobile-proyectos-big has-text-centered">
        <h1 class="proyectos-title-mobile">Centro Congreso de Querétaro</h1>
        <h2 class="proyectos-subtitle-mobile margin-top-mobile-proyectos">Instalación</h2>
        <p class="proyectos-text-mobile mt-5 mb-5">
          Descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, descripción del proyecto, 
        </p>
        <a class="proyectos-mobile-link">
            Ficha técnica
        </a>
      </div>
    );
  },
};

export default ProyectosMobile;
</script>

<style>
.proyectos-title-mobile {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.proyectos-subtitle-mobile {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.proyectos-text-mobile {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
}

.margin-top-mobile-proyectos {
    margin-top: 70px;
}

.margin-top-mobile-proyectos-big {
    margin-top: 160px;
}

.proyectos-mobile-link {
  font-size: 13px;
  font-weight: 500;
  color: white;
  margin-bottom: 50px;
  text-decoration: underline;
}
</style>