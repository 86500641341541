<script>
import { h } from "vue";

import Home from "./pages/Home.vue";
import { GrupoAlbarran, Mision, Vision, Valores, ValoresDetail, Carta } from "./pages/Nosotros.vue";
import { Servicios, AreaElectrica, Servicios1, Servicios2, Mantenimiento } from "./pages/Servicios.vue";
import { Proyectos, Jumex, Banamex, Reforma, Gobierno, Ibero, Tamayo, Express, Koi, Aeropuerto, Queretaro } from "./pages/Proyectos.vue";
import { Contacto, Form } from './pages/Contacto.vue';

import HomeMobile from "./pages/mobile/HomeMobile.vue";
import { GrupoAlbarranMobile, MisionMobile, Valores1Mobile, Valores2Mobile, Valores3Mobile, CartaMobile } from "./pages/mobile/NosotrosMobile.vue";
import { ServiciosMobile, ServiciosMobileAreaElectrica, ServiciosMobileServicios1, ServiciosMobileServicios2, ServiciosMobileServicios3, ServiciosMobileServicios4 } from "./pages/mobile/ServiciosMobile.vue";
import { ProyectosMobile, JumexMobile, BanamexMobile, ReformaMobile, GobiernoMobile, IberoMobile, TamayoMobile, ExpressMobile, KoiMobile, AeropuertoMobile, QueretaroMobile } from "./pages/mobile/ProyectosMobile.vue";
import { ContactoMobile, FormMobile } from './pages/mobile/ContactoMobile.vue';
import Clientes from './pages/mobile/Clientes.vue';

const componentMapper = {
  home: Home,
  nosotros_ga: GrupoAlbarran,
  nosotros_mision: Mision,
  nosotros_vision: Vision,
  nosotros_valores: Valores,
  nosotros_valores_detail: ValoresDetail,
  nosotros_carta: Carta,
  servicios_servicios: Servicios,
  servicios_area_electrica: AreaElectrica,
  servicios_servicios1: Servicios1,
  servicios_servicios2: Servicios2,
  servicios_mantenimiento: Mantenimiento,
  proyectos_proyectos: Proyectos,
  proyectos_jumex: Jumex,
  proyectos_banamex: Banamex,
  proyectos_reforma: Reforma,
  proyectos_gobierno: Gobierno,
  proyectos_ibero: Ibero,
  proyectos_tamayo: Tamayo,
  proyectos_express: Express,
  proyectos_koi: Koi,
  proyectos_aeropuerto: Aeropuerto,
  proyectos_queretaro: Queretaro,
  contacto_contacto: Contacto,
  contacto_form: Form,
  home_mobile: HomeMobile,
  nosotros_mobile_ga: GrupoAlbarranMobile,
  nosotros_mobile_mision: MisionMobile,
  nosotros_mobile_valores_1: Valores1Mobile,
  nosotros_mobile_valores_2: Valores2Mobile,
  nosotros_mobile_valores_3: Valores3Mobile,
  nosotros_mobile_carta: CartaMobile,
  servicios_mobile_servicios: ServiciosMobile,
  servicios_mobile_area_electrica: ServiciosMobileAreaElectrica,
  servicios_mobile_servicios_1: ServiciosMobileServicios1,
  servicios_mobile_servicios_2: ServiciosMobileServicios2,
  servicios_mobile_servicios_3: ServiciosMobileServicios3,
  servicios_mobile_servicios_4: ServiciosMobileServicios4,
  proyectos_mobile_proyectos: ProyectosMobile,
  proyectos_mobile_jumex: JumexMobile,
  proyectos_mobile_banamex: BanamexMobile,
  proyectos_mobile_reforma: ReformaMobile,
  proyectos_mobile_gobierno: GobiernoMobile,
  proyectos_mobile_ibero: IberoMobile,
  proyectos_mobile_tamayo: TamayoMobile,
  proyectos_mobile_express: ExpressMobile,
  proyectos_mobile_koi: KoiMobile,
  proyectos_mobile_aeropuerto: AeropuertoMobile,
  proyectos_mobile_queretaro: QueretaroMobile,
  contacto_mobile_form: FormMobile,
  contacto_mobile_contacto: ContactoMobile,
  clientes: Clientes
};

export default {
  name: "Proxy",
  props: ["component-selector"],
  render() {
    return h(componentMapper[this.componentSelector]);
  },
};
</script>