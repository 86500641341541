<template>
  <div class="top intranet-container">
    <div v-if="loggedIn">
      <Dashboard />
    </div>
    <div v-else>
      <Login @log-in="logIn" />
    </div>
  </div>
</template>

<script>
import Login from "./Login.vue";
import Dashboard from "./Dashboard.vue";

export default {
  components: { Login: Login, Dashboard: Dashboard },
  data() {
    return {
      loggedIn: false,
    };
  },
  methods: {
    logIn() {
      this.loggedIn = true;
    },
  },
};
</script>

<style>
.intranet-container {
  background: url("../../assets/bg-intranet.png");
  background-size: cover;
}
</style>