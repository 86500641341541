<template>
  <div class="column is-three-quarters">
    <div class="has-text-left animate__animated animate__fadeInUp animate__delay-1s second">
        <h1 class="title has-text-white mb-5">{{ optionSelected.title }}</h1>
        <div class="is-flex">
        <div
            class="selector-line"
            :class="{ selected: optionSelected.title === 'GRUPO ALBARRÁN' }"
            @click="selectOption('GA')"
        />
        <div
            class="selector-line"
            :class="{ selected: optionSelected.title === 'PYSE' }"
            @click="selectOption('PYSE')"
        />
        </div>
        <div class="text-box">
        <p class="mt-5">
            {{ optionSelected.text }}
        </p>
        </div>
    </div>
  </div>
</template>

<script>
const options = {
  PYSE: {
    title: "PYSE",
    text: "Más de 40 años de experiencia en ingeniería eléctrica – Somos tu aliado de confianza para diseñar, implementar y mantener proyectos eléctricos.",
  },
  GA: {
    title: "GRUPO ALBARRÁN",
    text: "Más de 40 años de experiencia – Brindamos soluciones efectivas de diseño, instalación y mantenimiento eléctrico.",
  },
};

export default {
  name: "Home",
  data: () => {
    return {
      optionSelected: options.GA,
    };
  },
  mounted() {
    /*
    setInterval(() => {
      if (this.optionSelected.title === options.PYSE.title) {
        this.optionSelected = options.GA;
      } else {
        this.optionSelected = options.PYSE;
      }
    }, 5000);
    */
  },
  methods: {
    selectOption: function (option) {
      this.optionSelected = options[option];
    },
  },
};
</script>

<style>
.title {
  font-size: 96px !important;
  margin-top: 12% !important;
}

@media only screen and (max-height: 650px) {
  .title {
    font-size: 76px !important;
    margin-top: 5% !important;
  }
}

.selector-line {
  border-top-style: solid;
  border-width: 1px;
  border-color: rgba(217, 217, 217, 0.75);
  width: 300px;
}

.selector-line:hover {
  border-width: 3px;
  cursor: pointer;
}

.selector-line.selected {
  border-width: 3px;
}

.text-box {
  width: 450px;
}
</style>
